
.portfolio-page{
    margin-top: 30px;
}


.DemoReel{
    border: none;
    width: 80%;
    height: 100vh;
}

.portfolioDemoReel{
    margin-top: 30px;
    height: 95vh;
}

/*FOR MOBILE*/
@media(max-width: 760px) {
    .DemoReel{
        border: none;
        width: 90%;
        height: 70vh;
    }

  }


/*FOR MOBILE*/
@media(max-width: 550px) {
    .DemoReel{
        border: none;
        width: 100%;
        height: 50vh;
    }

  }