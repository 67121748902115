 h1 {
    color: #48B749;
  }

h3{
    padding-left: 5rem;
    padding-right: 5rem;
}

p,h3{
    color:#4e5058
}

p{
    font-size: 1.3rem;
    line-height: 2rem;
    margin-bottom: 1.5rem;
    padding-left: 5rem;
    padding-right: 5rem;
}
    /**/


.App-link{
    color: #FFFFFF;
    padding: 5px 20px 5px 20px;
    border: none;
    border-radius: 20px;
    margin-top: 30px;
    margin-left: 15px;
    margin-right: 15px;
    text-decoration: none;
    background-color: #48B749;
}

.App-link:hover{
    /* color: #e5e6e5; */
    background-color: #3A963B
    /* border: #e5e6e5 2px solid; */
    
}

.App-link:active{
    /* color: #e5e6e5; */
    /* background-color: rgb(41, 128, 42); */
    background-color: #29802A
    /* border: #e5e6e5 2px solid; */
}

.bio-pic {
    max-height: 100%;
    width: 100%;
    min-width: 200px;
    max-width: 300px;
    border-radius: 5px;
  }


  .bold {
    font-weight: bold;
  }
  